.custom-loader {
    width: 60px;
    height: 60px;
    --c: radial-gradient(farthest-side, #000000 92%, #0000);
    background: var(--c) 50% 0,
        var(--c) 50% 100%,
        var(--c) 100% 50%,
        var(--c) 0 50%;
    background-size: 10px 10px;
    background-repeat: no-repeat;
    animation: loading .8s infinite linear;
    position: relative;
}

.custom-loader::before {
    content: "";
    position: absolute;
    inset: 0;
    margin: 3px;
    background: repeating-conic-gradient(#0000 0 35deg, #000000 0 90deg);
    mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
    border-radius: 50%;
}

@keyframes loading {
    100% {
        transform: rotate(.5turn)
    }
}