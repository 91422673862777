.wishlist-empty-image {
    width: 12%;
}

.wishlist-empty-box {
    width: 7rem !important;
}

@media screen and (max-width:600px) {
    .wishlist-empty-image {
        /* transform: scale(1.5); */
        margin-bottom: 2rem;
    }
}

.wishlist-content {
    /* height: 100vh; */
    margin: 0 0 3rem 0;
}

.wishlist-items {
    height: auto;
    box-shadow: var(--shadow);
    border-radius: var(--radius-2);
    padding: 10px;
    color: var(--clr-info) !important;
    margin: 0 0 3rem 0;
}


.wishlist-items-list {
    height: 65vh;
    overflow: auto;
}


.wishlist-items .form-label {
    color: var(--clr-dark) !important;
}