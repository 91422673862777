.loading-container {
    position: relative;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* -webkit-box-reflect: below 0 linear-gradient(transparent, transparent, #0005); */
    margin: 4rem 0 -8rem 0;
}

.loading-container .loading-container-loader {
    position: absolute;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    animation: animate 2s linear infinite;
}

.loading-container .loading-container-loader:nth-child(2),
.loading-container .loading-container-loader:nth-child(4) {
    animation-delay: -1s;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
        filter: hue-rotate(360deg);
    }

    100% {
        transform: rotate(360deg);
        filter: hue-rotate(0deg);
    }
}

.loading-container .loading-container-loader:nth-child(1)::before,
.loading-container .loading-container-loader:nth-child(2)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to top, transparent, rgba(0, 255, 249, 0.4));
    background-size: 100px 180px;
    background-repeat: no-repeat;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
}

.loading-container .loading-container-loader i {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 10px;
    background: #00fff9;
    border-radius: 50%;
    z-index: 100;
    box-shadow: 0 0 10px #00fff9,
        0 0 30px #00fff9,
        0 0 40px #00fff9,
        0 0 50px #00fff9,
        0 0 60px #00fff9,
        0 0 70px #00fff9,
        0 0 80px #00fff9,
        0 0 90px #00fff9,
        0 0 100px #00fff9;
}

.loading-container .loading-container-loader span {
    position: absolute;
    inset: 10px;
    background: #fff;
    border-radius: 50%;
    z-index: 1;
}