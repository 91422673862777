.share-icon {
    transition: 1.4s !important;
}

.share-icon svg {
    box-shadow: var(--shadow-1) !important;
    border-radius: 50% !important;
}

.share-icon:hover {
    transform: scale(1.2)
}