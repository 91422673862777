.shopping-cart-empty-image {
    width: 12%;
}

.shopping-cart-empty-box {
    width: 7rem !important;
}

@media screen and (max-width:600px) {
    .shopping-cart-empty-image {
        /* transform: scale(1.5); */
        margin-bottom: 2rem;
    }
}

.shopping-card {
    /* height: 100vh; */
    margin: 0 0 3rem 0;
}

.shopping-cart-items {
    height: auto;
    box-shadow: var(--shadow);
    border-radius: var(--radius-2);
    padding: 10px;
    color: var(--clr-blue) !important;
    margin: 0 0 3rem 0;
}


.items {
    height: 54vh;
    overflow: auto;
}

@media screen and (max-width:1200px) {
    .items {
        height: auto;
        max-height: 54vh;
    }
}

.promo {
    margin: 1.4rem 0 0 0;
}

.promo::before {
    content: "" !important;
    position: absolute !important;
    height: 1px !important;
    width: 40% !important;
    color: var(--clr-dark) !important;
    background-color: var(--clr-light-dark-2) !important;
    padding: 0px 0px 0px 0px;
    margin: -20px 0 0px -9px;
}

.code-promo-image img {
    height: 100%;
    width: 8rem;
    transform: scale(.6);
    margin: 0 -25px 0 -30px;
}

.shopping-card .shopping-form-check-input:focus {
    /* border: 2px solid var(--clr-lightpink) !important; */
    border: none;
    outline: none !important;
    box-shadow: none !important;
    box-shadow: 0 0 0 0.25rem #e7695d48 !important;

}

.code-promo-input input::placeholder {
    color: var(--gray-3);
    font-size: 13px;
}

.code-promo-input input {
    width: 10rem;
    color: var(--gray-2) !important;
    font-size: 15px;
    font-family: var(--font-family-outfit-reg);
}

.shopping-cart-items .form-label {
    color: var(--clr-dark) !important;
}

.checkout-input {
    color: var(--clr-dark) !important;
    font-size: 13px !important;
    font-family: var(--font-family-outfit-reg) !important;
}

.checkout-input.holdername {
    text-transform: uppercase !important;
}

.shopping-cart-checkoutt::before {
    background-image: radial-gradient(closest-side, transparent 9px, rgba(84, 84, 84, 0.05) 100%);
    top: -10px;
    content: "";
    display: block;
    height: 10px;
    width: 100%;
    position: relative;
    background-color: transparent;
    background-size: 20px 20px;
    background-position: 0 10px, 40px 40px;
}

.shopping-cart-checkoutt::after {
    background-image: radial-gradient(closest-side, transparent 9px, rgba(84, 84, 84, 0.05) 100%);
    clear: both;
    content: "";
    display: block;
    height: 10px;
    width: 100%;
    position: relative;
    background-color: transparent;
    background-size: 20px 20px;
    background-position: 0 10px, 40px 40px;
    bottom: -10px;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

/* .checkout-input::placeholder,
.checkout-input.cardnumber::placeholder,
.checkout-input.holdername::placeholder {
    color: var(--clr-dark) !important;
    font-size: 13px !important;
    opacity: .6;
} */

input::placeholder {
    color: var(--clr-dark) !important;
    font-size: 13px !important;
    opacity: .6 !important;
}

.input-group-text {
    background: none !important;
    border-left: none !important;
}

.input-image {
    width: 16px;
    transform: scale(1.5);
}

@media screen and (max-width:768px) {
    .code-promo-btn {
        /* left: 85px !important; */
        position: relative;
        /* margin: 10px 0 0 0; */
        margin: 10px 0 10px 0;
    }
}

.code-promo-btn button {
    font-size: 13px;
    letter-spacing: .7px;
    outline: none !important;
    /* border: none !important; */
}

.shopping-cart-checkout {
    height: auto;
    /* height: 60vh; */
    box-shadow: var(--shadow);
    border-radius: var(--radius-2);
    padding: 1rem 1rem 14px;
    /* background-color: #eeeef0; */
}

.shopping-cart-checkout-soustotal div,
.shopping-cart-checkout-expedition-title span,
.form-check-label {
    font-size: 14px;
    color: var(--clr-blue);
    letter-spacing: .9px;
}

.shopping-cart-checkout-title span {
    letter-spacing: .9px;
}


.form-check-input:checked {
    background-color: var(--clr-lightpink) !important;
    border-color: var(--clr-lightpink) !important;
    outline: none !important;
    box-shadow: 0 0 0 0.25rem #e7695d48 !important;
}

.form-check-label {
    color: var(--clr-dark) !important;
    cursor: pointer;
    transition: var(--transition);
}

.form-check-label:hover {
    color: var(--clr-pink) !important;
}

.shopping-cart-checkout-expedition-note span {
    font-size: 13px;
    color: var(--gray-2);
    font-family: var(--font-family-outfit-medium);
}

.shopping-cart-checkout-expedition-btn button {
    color: var(--clr-light) !important;
    font-size: 12px !important;
    background-color: var(--clr-blue) !important;
    border: none !important;
    box-shadow: var(--shadow-1);
    padding: 10px 0px;
}

.shopping-cart-checkout-payment-mastercard {
    height: 100%;
    width: 34%;
}

.shopping-cart-checkout-payment span {
    color: var(--clr-blue);
}

.shopping-cart-checkout-total {
    letter-spacing: .7px;
    color: var(--clr-dark);
}

.shopping-cart-checkout-total-btn {
    color: var(--clr-light) !important;
    font-size: 13px !important;
    background-color: var(--clr-blue) !important;
    border: none !important;
    box-shadow: var(--shadow-1);
    padding: 11px 3rem !important;
    margin: -2rem 0 0 0 !important;
}

button {
    transition: var(--transition) !important;
}

.shopping-cart-btn:hover {
    background-color: var(--clr-light-blue) !important;
}

.back-to-boutique {
    font-family: var(--font-family-outfit-reg) !important;
    color: var(--clr-blue) !important;
}

.back-to-boutique:hover {
    color: var(--clr-pink) !important;
}

.shopping-cart-note {
    color: var(--clr-dark);
    opacity: .9;
    font-size: 15px;
    font-family: var(--font-family-varela-round);
    font-weight: 600;
}

.shopping-cart-livraison {
    color: var(--clr-dark);
    opacity: .9;
    font-size: 17px;
    font-family: var(--font-family-varela-round);
    font-weight: 600;
}

.shopping-cart-note-icon {
    font-size: 22px;
    color: var(--clr-success);
    margin: -3px 4px 0 0px;
}

.shopping-cart-notfree-icon {
    font-size: 27.8px;
    margin: -3px 2.8px 0px -2px;
}

.shopping-cart-free-icon {
    font-size: 23px;
    margin: -3px 6px 0 0px;
}


/* ============= */

.btn-delete-codepromo {
    height: 2.6rem;
    width: 11rem;
    background-color: var(--clr-pink);
    padding: 7px 40px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    cursor: pointer;
    border-radius: var(--radius);
    border: 2px dashed var(--clr-pink);
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
    box-shadow: var(--shadow-1);
    transition: .4s;
    font-size: 14px;
}

.btn-delete-codepromo span:last-child {
    display: none;
}

.btn-delete-codepromo:hover {
    transition: .4s;
    border: 2px dashed var(--clr-pink);
    background-color: #fff;
    color: var(--clr-pink);
}