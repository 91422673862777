.wishlist-item {
    border-radius: var(--radius);
    border: var(--border-solid);
    box-shadow: var(--shadow-2);
    margin: 0 0 1.5rem 0;
}

.wishlist-item-content {
    height: 9rem;
    position: relative;
    overflow: hidden;
}

.wishlist-item-image {
    height: 8.6rem !important;
}

@media screen and (max-width:768px) {
    .wishlist-item-content {
        height: 14rem;
    }
}

@media screen and (max-width:578px) {
    .wishlist-item-content {
        height: 15rem;
    }
}

.wishlist-item-img {
    width: 100%;
    margin: 0 0 0 0.6rem;
    object-fit: scale-down !important;
    overflow: hidden;
    height: 100%;
    padding: 5px 0;
}

.wishlist-item-quantity {
    box-shadow: var(--shadow-2);
    height: 41px;
    width: 6rem;
    border-radius: var(--radius-2);
}

.wishlist-item-quantity-value {
    font-size: 18px;
    font-family: var(--font-family-outfit-reg);
    font-weight: 600;
}

.wishlist-item-quantity-icon {
    cursor: pointer;
    color: var(--clr-dark);
    font-size: 25px;
}

.wishlist-item-btn {
    border-radius: var(--radius-2) !important;
    width: 200px !important;
    transition: var(--transition);
}

.wishlist-item-addtocart {
    border: 1px solid var(--clr-info) !important;
}

.wishlist-item-addtocart:hover {
    color: var(--clr-light) !important;
    background-color: var(--clr-info) !important;
}

.wishlist-item-delete {
    border: 1px solid var(--clr-pink) !important;
}

.wishlist-item-delete:hover {
    color: var(--clr-light) !important;
    background-color: var(--clr-pink) !important;
}

.wishlist-item-title {
    font-size: 18px !important;
}