.pack-container>.container {
    border-radius: var(--radius-2);
    /* box-shadow: var(--shadow-4); */
    /* padding: 0px 20px 20px 20px; */
    /* background: rgba(234, 234, 234, 0.25); */
}

.pack-item {
    height: 30rem;
    border: var(--border-dashed);
    border-radius: 10px;
    box-shadow: var(--shadow-2);
    transform: scale(.95);
    max-width: 330px !important;
    min-width: 309px !important;
    overflow: hidden;
    background-color: var(--clr-light);
}

.pack-img {
    height: 100% !important;
    width: 100% !important;
    object-fit: scale-down;
    border-radius: var(--radius-2);
}

.pack-img:hover {
    transform: scale(1.15) !important;
}

.pack-image {
    position: relative;
    height: 18rem !important;
    margin: 3.2rem 0 0 0 !important;
    /* box-shadow: var(--shadow-1); */
    border-radius: var(--radius-2);
    overflow: hidden;
    /* padding: 9px 10px; */
    width: 100% !important;
}

.fixed-style {
    padding: 7px 14px !important;
    transform: scale(.92) !important;
}

.pack-title {
    font-size: 18px !important;
    font-family: var(--font-family-varela-round) !important;
    opacity: 0.9 !important;
    font-weight: 600;
    line-height: 1.6rem;
}


.pack-info {
    margin: 1rem 0 0 0;
    max-height: 6rem;
}

.pack-price {
    font-size: 20px;
    font-weight: 700;
    /* margin: 0 0 3.1rem 0; */
}

.time-down {
    width: 100%;
    left: 0px;
    padding: 6px 10px;
    z-index: 9;
    background-color: var(--clr-dark);
    color: var(--clr-light);
    font-size: 18px;
}


@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width:920px) and (min-width:768px) {
    .pack-item {
        max-height: 28.5rem;
    }

}

@media screen and (max-width:768px) and (min-width:576px) {
    .pack-container .pack-info {
        margin: -0.1rem 0 0 0;
    }

    .pack-container .view {
        margin: -8rem 0 1rem 0;
    }

    /* .pack-container .pack-price {
        margin: 0 0 3.8rem 0;
    } */

    .pack-container .submit-btn {
        font-size: 12px !important;
        padding: 14px 2rem !important;
        margin: -2rem 0 0.3rem 0 !important;
        transform: scale(1.1);
    }
}

@media screen and (max-width:576px) {
    .pack-item {
        max-width: 390px !important;
    }

}


@media screen and (max-width:590px) and (min-width:100px) {
    .boutique.isgrid .pack-info {
        margin: .5rem 0 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        /* margin: -3.2rem 0 0 0; */
    }

    .boutique.isgrid a .pack-title {
        font-size: 17px !important;
        line-height: 1.3rem !important;
    }

    .boutique.isrow .pack-info {
        margin: .4rem 0 0 0 !important;
    }

   
    .boutique.isrow .pack-image {
        width: 85% !important;
    }


    .pack-item.isrow {
        max-height: 32rem !important;
        min-height: 32rem !important;
    }

    .pack-item.isgrid {
        max-height: 25.5rem !important;
    }

    .pack-item.isrow .product-img {
        width: 70% !important;
    }

}