.product-slider .carousel .control-arrow,
.product-slider .carousel.carousel-slider .control-arrow {
    -webkit-transition: all .25s ease-in;
    -moz-transition: all .25s ease-in;
    -ms-transition: all .25s ease-in;
    -o-transition: all .25s ease-in;
    transition: all .25s ease-in;
    opacity: .4;
    filter: alpha(opacity=40);
    position: absolute;
    z-index: 2;
    top: 20px;
    background: none;
    border: 0;
    font-size: 32px;
    cursor: pointer
}

/* .carousel .control-arrow:focus,
.carousel .control-arrow:hover {
    opacity: 1;
    filter: alpha(opacity=100)
} */

.product-slider .carousel:hover .control-arrow,
.product-slider .carousel:focus .control-arrow {
    opacity: 1;
    filter: alpha(opacity=100)
}


.product-slider .carousel .control-disabled.control-arrow {
    opacity: 1;
    filter: alpha(opacity=0);
    cursor: inherit;
    display: block !important;
}

.product-slider .carousel .control-prev.control-arrow {
    left: -1rem;
    display: block !important;
}

/* New ::after */

.product-slider .carousel .control-prev.control-arrow::after {
    content: "";
    background-image: url('../../../../assets/images/slider/less-than-symbol.png');
    display: block;
    width: 3rem;
    height: 3rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    color: var(--clr-dark);
    transform: scale(.75);
    margin: 0px 0px 0 17px;
    /* margin: 0px 0px 0 -3rem; */
    transition: all .7s;
    background-color: var(--clr-dark);
    border-radius: 10px;
}

.product-slider .carousel:hover .control-prev.control-arrow::after {
    margin: 0px 0px 0 17px;
}

.product-slider .carousel .control-next.control-arrow::after {
    content: "";
    background-image: url('../../../../assets/images/slider/greater-than-symbol.png');
    display: block;
    width: 3rem;
    height: 3rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    color: var(--clr-dark);
    margin: 0 0 0 -5rem;
    /* margin: 0 0 0 0rem; */
    transform: scale(.75);
    transition: all .7s;
    background-color: var(--clr-dark);
    border-radius: 10px;
}

.product-slider .carousel:hover .control-next.control-arrow::after {
    margin: 0 0 0 -5rem;
}

.product-slider .carousel .control-next.control-arrow {
    right: -2rem;
    display: block !important;
}

.product-slider .carousel-root {
    outline: none
}

.product-slider .carousel {
    position: relative;
    width: 100%
}

@media screen and (max-width:992px) {}


@media screen and (max-width:768px) {
    /* .product-slider .carousel {
        width: 80% !important;
    } */

    .product-slider .p-sliderImg {
        width: 80% !important;
        display: inline-block;
    }
}

@media screen and (max-width:578px) {}

.product-slider .carousel * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.product-slider .carousel img {
    width: 100%;
    display: inline-none;
    pointer-events: none
}

.product-slider .carousel .carousel {
    position: relative
}

.product-slider .carousel .control-arrow {
    outline: 0;
    border: 0;
    background: none;
    top: 50%;
    margin-top: -13px;
    font-size: 18px
}

.product-slider .carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden
}

.product-slider .carousel .thumbs {
    -webkit-transition: all .15s ease-in;
    -moz-transition: all .15s ease-in;
    -ms-transition: all .15s ease-in;
    -o-transition: all .15s ease-in;
    transition: all .15s ease-in;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap
}

.product-slider .carousel .thumb {
    -webkit-transition: border .15s ease-in;
    -moz-transition: border .15s ease-in;
    -ms-transition: border .15s ease-in;
    -o-transition: border .15s ease-in;
    transition: border .15s ease-in;
    display: inline-none;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid var(--clr-dark);
    padding: 2px
}

.product-slider .carousel .thumb:focus {
    border: 3px solid #ccc;
    outline: none
}

.product-slider .carousel .thumb.selected,
.product-slider .carousel .thumb:hover {
    border: 3px solid #333
}

.product-slider .carousel .thumb img {
    vertical-align: top
}

.product-slider .carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden
}

.product-slider .carousel.carousel-slider .control-arrow {
    top: 0;
    color: var(--clr-dark);
    font-size: 26px;
    bottom: 0;
    margin-top: 0;
    padding: 5px;
}


.product-slider .carousel.carousel-slider .control-arrow:hover {
    background: rgba(0, 0, 0, 0.2)
}

.product-slider .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    -webkit-transition: height .15s ease-in;
    -moz-transition: height .15s ease-in;
    -ms-transition: height .15s ease-in;
    -o-transition: height .15s ease-in;
    transition: height .15s ease-in
}

.product-slider .carousel .slider-wrapper.axis-horizontal .slider {
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex
}

.product-slider .carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-direction: column;
    flex-flow: column
}

.product-slider .carousel .slider-wrapper.axis-vertical {
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex
}

.product-slider .carousel .slider-wrapper.axis-vertical .slider {
    -webkit-flex-direction: column;
    flex-direction: column
}

.product-slider .carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%
}

.product-slider .carousel .slider.animated {
    -webkit-transition: all .35s ease-in-out;
    -moz-transition: all .35s ease-in-out;
    -ms-transition: all .35s ease-in-out;
    -o-transition: all .35s ease-in-out;
    transition: all .35s ease-in-out
}

.product-slider .carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center
}

.product-slider .carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0
}

.product-slider .carousel .slide iframe {
    display: inline-none;
    width: calc(100% - 80px);
    margin: 0 40px 40px;
    border: 0
}

.product-slider .carousel .slide .legend {
    -webkit-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    border-radius: 10px;
    background: #000;
    color: var(--clr-dark);
    padding: 10px;
    font-size: 12px;
    text-align: center;
    opacity: 0.25;
    -webkit-transition: opacity .35s ease-in-out;
    -moz-transition: opacity .35s ease-in-out;
    -ms-transition: opacity .35s ease-in-out;
    -o-transition: opacity .35s ease-in-out;
    transition: opacity .35s ease-in-out
}

.product-slider .carousel .control-dots {
    position: absolute;
    bottom: 0;
    margin: 10px 0 4rem 0;
    padding: 0;
    text-align: center;
    width: 100%;
    z-index: 1;

    display: flex;
    justify-content: center;
    color: var(--clr-dark);
    /* letter-spacing: 10px; */
}

.product-slider .my-indicator {
    cursor: pointer;
    padding: 0 2rem 0 0rem;
    opacity: .7;
    font-size: 12px;
}

.product-slider .selected {
    opacity: 1 !important;
    margin: 0px 0px 0 0px !important;
    transition: all .7s !important;
}

.product-slider .selected::after {
    content: "" !important;
    position: absolute !important;
    height: 1px !important;
    width: 48px !important;
    color: var(--clr-light) !important;
    background-color: var(--clr-light) !important;
    margin: 8px 0px 0px 9px !important;
    border-radius: 5px !important;
    display: none;
}

@media (min-width: 960px) {
    .product-slider .carousel .control-dots {
        bottom: 0
    }
}

.product-slider .carousel .control-dots .dot {
    -webkit-transition: opacity .25s ease-in;
    -moz-transition: opacity .25s ease-in;
    -ms-transition: opacity .25s ease-in;
    -o-transition: opacity .25s ease-in;
    transition: opacity .25s ease-in;
    opacity: .3;
    filter: alpha(opacity=30);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    background: var(--clr-dark);
    border-radius: 3px;
    width: 31px;
    height: 2px;
    cursor: pointer;
    display: none !important;
    /* display: inline-none; */
    margin: 0 8px;
}

.product-slider .carousel .control-dots .dot.selected,
.product-slider .carousel .control-dots .dot:hover {
    opacity: 1;
    filter: alpha(opacity=100);
    display: none !important;
}

.product-slider .carousel .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
    color: var(--clr-dark);
    display: none;

}

.product-slider .carousel:hover .slide .legend {
    opacity: 1
}


/* Slider */

.product-slider .slider-container {
    /* position: absolute; */
    margin: -80px 0 0 0;
}

.product-slider .p-sliderImgi {
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    height: 100% !important;
    object-fit: cover !important;
    overflow: hidden !important;
}

.product-slider .p-sliderImgold {
    width: 100% !important;
    /* height: auto !important; */
    overflow: hidden !important;
    cursor: grab !important;
    max-height: 20rem;
    margin: 5rem 0px 10px 0px;
}

.product-slider .p-sliderImgold img {
    width: 100% !important;
    height: 100% !important;
    object-fit: scale-down !important;
    /* margin: 80px 0 0 0 !important; */
}

.product-slider .thumbs-wrapper {
    /* display: none !important; */
    display: none !important;
}

a[style] {
    all: unset;
}

.Window__WindowComponent-sc-1wwhwms-1 a:last-child {
    display: none !important;
}

.Window__WindowComponent-sc-1wwhwms-1:nth-child(3) {
    display: none !important;
}

@media screen and (max-width:590px) {
    .product-slider .slider-container {
        margin: -5rem 0 0 0 !important;
        overflow: hidden !important;
    }
}

/* new Style */

.product-slider .p-sliderImg img {
    width: 100% !important;
    object-fit: scale-down !important;
    max-height: 65vh !important;
    aspect-ratio: 4/5;
}

.product-slider .p-sliderImg {
    width: 100% ;
    overflow: hidden !important;
    cursor: grab !important;
    max-height: 65vh !important;
    margin: 6rem 0px 10px 0px;
}

.product-slider>.carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-direction: column;
    flex-flow: column;
    max-height: 100% !important;
    height: 65vh !important;
}

.product-slider>.carousel .slider-wrapper.axis-horizontal .slider {
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
    height: 100% !important;
    max-height: 65vh !important;
}

.product-slider>.carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    transition: height .15s ease-in;
    max-height: 65vh;
}