.edit-form{
    box-shadow: var(--shadow-2);
    height: auto;
    width: 100%;
    border-radius: var(--radius-2);
    padding: 2rem;
    border: var(--border-dashed);
}

.edit-account-btn{
    color: var(--clr-light) !important;
    font-size: 14px !important;
    background-color: var(--clr-blue) !important;
    border: none !important;
    box-shadow: var(--shadow-1);
    padding: 16px 3rem !important;
    margin: -2rem 0 0 0 !important;
    text-transform: uppercase;
    letter-spacing: .7px;
}