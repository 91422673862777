.submit-btn {
    font-size: 14px !important;
    box-shadow: var(--shadow-pink);
    background-color: var(--clr-pink) !important;
    border: var(--border-pink) !important;
    color: var(--clr-light) !important;
    /* padding: 11px 1.3rem !important; */
    margin: -2rem 0 0 0 !important;
    margin-top: -2rem;
    text-transform: uppercase !important;
    /* letter-spacing: .5px !important; */
    transition: var(--transition) !important;
}

.submit-btn:hover {
    border: var(--border-pink) !important;
    background-color: var(--clr-pink-light) !important;
    color: var(--clr-pink) !important;
}

.addtocart-icon {
    font-size: 20px !important;
    margin: 0px 9px 1px -7px !important;
    transform: scale(1.3) !important;
}

/* revers */

.revers-submit-btn {
    font-size: 14px !important;
    box-shadow: var(--shadow-pink);
    border: var(--border-pink) !important;
    background-color: var(--clr-pink-light) !important;
    color: var(--clr-pink) !important;
    padding: 11px 2.3rem !important;
    margin: -2rem 0 0 0 !important;
    margin-top: -2rem;
    text-transform: uppercase !important;
    /* letter-spacing: 1.4px !important; */
    transition: var(--transition) !important;
}

.revers-submit-btn:hover {
    background-color: var(--clr-pink) !important;
    border: var(--border-pink) !important;
    color: var(--clr-light) !important;
}

.order-now-icon {
    font-size: 18px !important;
    margin: -4px 9px 0px -7px !important;
    transform: scale(1.2) !important;
}