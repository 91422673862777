.blog-container {
    margin-bottom: 5rem !important;
    /* max-width: 1560px !important; */
}

.blog-content {
    height: 25rem;
    border-radius: var(--radius-2);
    box-shadow: var(--shadow-2);
    background-color: var(--clr-light);
    transition: var(--transition);
}

.blog-content:hover {
    box-shadow: var(--shadow-4);
}


.blog-image {
    width: 93%;
    height: 100%;
    overflow: hidden;
    border-radius: var(--radius-2);
}

.blog-img {
    width: 100%;
    height: 100%;
    border-radius: var(--radius-2);
    object-fit: cover;
    transition: var(--transition);
}

.blog-img:hover {
    transform: scale(1.1);
}

.blog-desc {
    font-size: 1.15rem !important;
    height: 22vh;
}

.top-image-desc {
    color: var(--clr-pink);
    transition: var(--transition);
}

.top-image-desc:hover {
    color: var(--clr-purple);
}

/* Media Query */

@media screen and (min-width:1650px) {
    .blog-container {
        max-width: 1560px !important;
    }

}

@media screen and (max-width:1200px) {
    .blog-desc {
        font-size: 1rem !important;
    }

}

@media screen and (max-width:992px) {
    .blog-desc {
        font-size: 1.15rem !important;
    }

    .blog-image {
        width: 100%;
    }

    .blog-content {
        height: auto;
        /* max-height: 43rem; */
    }

    .blog-col-img {
        height: 25rem !important;
    }

}

@media screen and (max-width:578px) {
    .blog-container {
        max-width: 370px !important;
    }

    .blog-col-img {
        height: 15rem !important;
    }

}