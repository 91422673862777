.details-categories-container {
    margin-bottom: 18rem !important;
    /* max-width: 1560px !important; */
}

.details-categories-content {
    height: 25rem;
    /* max-height: 25rem; */
    border-radius: var(--radius-2);
    box-shadow: var(--shadow-2);
    /* background-color: #272b3a09; */
    background-color: var(--clr-light);
    /* background-color: #272b3a04; */
}


.details-categories-image {
    width: 93%;
    height: 100%;
    overflow: hidden;
    border-radius: var(--radius-2);
}

.details-categories-img {
    width: 100%;
    height: 100%;
    border-radius: var(--radius-2);
    object-fit: cover;
    transition: var(--transition);
}

.details-categories-img:hover {
    transform: scale(1.1);
}

.details-categories-desc {
    font-size: 1.15rem !important;
}

/* Media Query */

@media screen and (min-width:1650px) {
    .details-categories-container {
        max-width: 1560px !important;
    }

}

@media screen and (max-width:1200px) {
    .details-categories-desc {
        font-size: 1rem !important;
    }

}

@media screen and (max-width:992px) {
    .details-categories-desc {
        font-size: 1.15rem !important;
    }

    .details-categories-image {
        width: 100%;
    }

    .details-categories-content {
        height: auto;
        /* max-height: 43rem; */
    }

    .details-categories-col-img {
        height: 25rem !important;
    }

}

@media screen and (max-width:578px) {
    .details-categories-container {
        max-width: 370px !important;
    }

    .details-categories-col-img {
        height: 15rem !important;
    }

}