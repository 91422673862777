.offcanvas.offcanvas-end {
    width: 100% !important;
    border-radius: var(--radius-2);
    border: none !important;
    box-shadow: var(--shadow-5);
    left: 100%;
}

.offcanvas.offcanvas-start {
    width: 80% !important;
    border-radius: var(--radius-2);
    border: none !important;
    box-shadow: var(--shadow-5);
}

@media screen and (max-width:768px) {
    .offcanvas.offcanvas-start {
        width: 100% !important;
    }
}

.offcanvas-close-btn {
    border: none !important;
    width: 1rem !important;
    height: 1rem !important;
    margin: 0 0 0 0 !important;
    display: contents !important;
    transition: var(--transition);
}

.offcanvas-close-icon {
    font-size: 36px;
    display: block;
    transition: var(--transition);
}

.offcanvas-close-btn:hover .offcanvas-close-icon {
    transform: rotate(90deg);
}