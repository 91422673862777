.details-blog-img {
    width: 80%;
    height: 100%;
    border-radius: var(--radius-2);
    object-fit: cover;
    transition: var(--transition);
    box-shadow: var(--shadow-2);
}

.product-details-desc img {
    width: 70%;
    height: 100%;
    border-radius: var(--radius-2);
    box-shadow: var(--shadow-pink);
}


.details-blog-video {
    position: relative !important;
    padding-bottom: 56% !important;
    height: 0 !important;
    overflow: hidden !important;
    border-radius: var(--radius-2) !important;
    /* box-shadow: var(--shadow-1) !important; */
    transform: scale(100%) !important;
}

.details-blog-video-container {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 81% !important;
}

.details-blog-video-container iframe {
    width: 100% !important;
    height: 100% !important;
    border-radius: var(--radius-2) !important;
    box-shadow: var(--shadow-1) !important;
}