.shopping-cart-item {
    border-radius: var(--radius);
    border: var(--border-dashed);
    box-shadow: var(--shadow);
    margin: 0 0 1rem 0;
}

.shopping-cart-item-content {
    height: 7rem;
    position: relative;
    overflow: hidden;
}

.shopping-cart-item-image {
    height: 6rem !important;
}

@media screen and (max-width:768px) {
    .shopping-cart-item-content {
        height: 14rem;
    }
}

@media screen and (max-width:578px) {
    .shopping-cart-item-content {
        height: 15rem;
    }
}

.shopping-cart-item-img {
    width: 100%;
    margin: 0 0 0 0.6rem;
    object-fit: scale-down !important;
    overflow: hidden;
    height: 100%;
    padding: 5px 0;
}

.shopping-cart-item-quantity {
    box-shadow: var(--shadow-2);
    height: 44px;
    width: 7rem;
    border-radius: var(--radius-2);
    padding-top: 1%;
}

.shopping-cart-item-quantity-value {
    font-size: 18px;
    font-family: var(--font-family-outfit-reg);
    font-weight: 600;
}

.shopping-cart-item-quantity-icon {
    cursor: pointer;
    color: var(--clr-dark);
    font-size: 27px;
    border: var(--border-solid);
    border-radius: 50%;
}

.shopping-cart-item-title {
    font-size: 14px;
}

.shopping-cart-item-prix {
    font-size: 14px;
}