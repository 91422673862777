/* SubShopping Card Box */
.subshopping-cart-box {
    position: absolute;
    top: calc(100% + 2rem);
    right: 13rem;
    /* right: 22%; */
    transform: translateX(50%);
    width: 25rem;
    background: var(--clr-light);
    border-radius: 10px;
    box-shadow: var(--shadow-2);
    font-family: var(--font-family-outfit-reg);
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.7s ease, max-height 0.7s ease, margin 0.7s ease, all 0.7s ease;
    margin: -4rem 0 2rem 0rem !important;
    padding: 1rem 0 0 0;
}

@media screen and (max-width:578px) {

    .subshopping-cart-box {
        right: 50%;
        width: 97%;
    }

    .subshopping-cart-box.show {
        right: 50%;
        width: 97%;
    }

    .subvariant-title {
        font-size: 12px;
        line-height: 1.1;
    }
}

.subshopping-cart-box.show {
    opacity: 1;
    max-height: 100vh;
    margin: -32px 0 13rem 0rem !important;
    z-index: 999;
}

.card-content {
    max-height: 22rem;
    /* min-height: 15rem; */
    /* overflow-y: scroll; */
}

.subshopping-cart-message {
    background-color: var(--clr-pink);
    padding: 0.7rem 0;
    border-radius: var(--radius);
    color: var(--clr-light);
    box-shadow: var(--shadow-2);
    transition: var(--transition) !important;
}

.subshopping-cart-value {
    color: var(--clr-pink) !important;
    margin: -12px 0 0 -3px !important;
    position: absolute;
    font-family: var(--font-family-outfit-reg);
    background-color: var(--clr-blue);
    width: 22px;
    height: 22px;
    border-radius: 50%;
    text-align: center;
}

.subshopping-cart-content {
    color: var(--clr-light) !important;
    margin: 0.7px 0px 0px -0.7px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 13px;
}

.subshopping-cart-empty-image {
    width: 40%;
}

.card-checkout {
    margin: 0px 0 0px 0;
    box-shadow: var(--shadow);
    padding: 10px 0 0 0;
}

.card-checkout-btn {
    font-size: 15px !important;
    color: var(--clr-pink) !important;
    letter-spacing: .4px;
    transition: var(--transition) !important;
}

.card-checkout-btn:hover {
    color: var(--clr-pink) !important;
    /* margin: 0 0 0 -2rem !important; */
    margin-left: -2rem !important;
}

.card-checkout-icon {
    color: var(--clr-pink);
    font-size: 20px;
    transition: var(--transition) !important;
    margin: -3px 0 0 0px;
}

.card-checkout-btn:hover .card-checkout-icon {
    transform: translate(9rem, 0%);
    color: var(--clr-red);
}

.subshopping-cart-item {
    border-radius: var(--radius);
    box-shadow: var(--shadow);
    height: 7rem;
    margin: 0rem 0 0rem 0;
}

.subshopping-cart-item:not(:last-child) {
    margin: 0rem 0 0.5rem 0;
}

.subshopping-cart-item-img {
    height: 100%;
    /* height: 72px; */
    width: 100%;
    border-radius: var(--radius);
    object-fit: scale-down;
    overflow: hidden;
    padding: 5px 0;
}

.subshopping-cart-item-details {
    color: var(--clr-blue);
}

.subshopping-cart-item-title {
    font-size: 14px;
}

.subshopping-cart-item-prix {
    font-size: 15px;
    color: var(--clr-pink);
    margin: 3px 0 0 0;
}

.trash-icon {
    color: var(--clr-pink);
    font-size: 20px;
    box-shadow: var(--shadow-3);
    border-radius: var(--radius);
    width: 25px;
    height: 25px;
    cursor: pointer;
    transition: var(--transition) !important;
    margin: 0 0 0 1px;
}

.trash-icon:hover {
    transform: scale(1.1);
}

.trash {
    margin: 0 0 0 -15px;
    transform: translate(12%, -17%);
}

.subshopping-cart-close-icon {
    color: var(--clr-pink);
    font-size: 17px;
    margin: -1px -3px 0 -3px;
}

.card-badge {
    width: 23px !important;
    height: 23px !important;
    margin: 0px 0px 0px 2px !important;
    padding: 3px 0 0 0 !important;
    font-size: 15px !important;
    font-family: var(--font-family-outfit-medium);
}

.command-btn {
    background-color: var(--clr-light);
    padding: 1rem 7rem;
    border: 2px solid var(--clr-light-dark-2);
    border-radius: var(--radius);
    color: var(--clr-blue);
    box-shadow: var(--shadow-2);
    transition: var(--transition) !important;
    margin: 10px 0;
    letter-spacing: 1px;
}

.command-btn:hover {
    color: var(--clr-light);
    background-color: var(--clr-dark) !important;
}

.total-price {
    color: var(--clr-blue);
    letter-spacing: 1px;
    margin: 1rem 0 0 0;
}

hr {
    background-color: var(--clr-light-dark-2) !important;
}

.total-price::before {
    content: "" !important;
    position: absolute !important;
    height: 1px !important;
    width: 94% !important;
    color: var(--clr-dark) !important;
    background-color: var(--clr-light-dark-2) !important;
    padding: 0px 0px 0px 0px;
    margin: 2px 0 0px -26px;
}


.subvariant-icon-content {
    width: 25px !important;
    height: 25px !important;
    border-radius: 50% !important;
    position: relative !important;
}

.subvariant-icon {
    width: 100% !important;
    height: 100% !important;
    border-radius: 50% !important;
    overflow: hidden !important;
    position: absolute !important;
    padding: 2px 2px !important;
}

.subvariant-title {
    font-size: 13px;
}