.login-form {
  box-shadow: var(--shadow-2);
  height: auto;
  width: 50rem !important;
  /* width: 100% !important; */
  border-radius: var(--radius-2);
  padding: 2rem;
  /* border: var(--border-dashed); */
}

@media screen and (max-width:992px) {
  .login-form {
    width: 100% !important
  }
}

.login-icon {
  height: 100%;
  width: 40px;
}

.form-check-content {
  font-size: 12px !important;
  color: var(--clr-blue);
  transition: var(--transition) !important;
}

.form-check-content:hover {
  color: var(--clr-light-blue);
}

input:focus {
  border: 2px solid var(--clr-blue) !important;
  outline: none !important;
  box-shadow: none !important;
  /* box-shadow: 0 0 0 0.25rem #e7695d2a !important; */
}

.sign-in-up {
  padding: 7px 0px !important;
  border-radius: var(--radius-2) !important;
  font-size: 18px !important;
  letter-spacing: .5px !important;
  width: 10rem;
}

.active-form {
  border: 2px solid var(--clr-pink) !important;
  background-color: var(--clr-pink) !important;
  color: var(--clr-light) !important;
  box-shadow: var(--shadow-2) !important;
}

.inactive-form {
  border: 2px solid var(--bs-gray-400) !important;
  background-color: #fff2fc2b !important;
  color: #272b3a9e !important;
}

.kep-login-facebook {
  display: none !important;
}

.login-icon {
  transition: var(--transition) !important;
  cursor: pointer !important;
}

.login-icon:hover {
  transform: scale(1.10) !important;
}

.google-btn {
  display: block !important;
  border: none !important;
  border-radius: 3px !important;
  color: black !important;
  cursor: pointer !important;
  font-size: 19px !important;
  margin: 0px 0px 0 -3px !important;
  width: calc(100% - 10px) !important;
  overflow: hidden !important;
  padding: 0px 10px 0px 8px !important;
  user-select: none !important;
  height: 50px !important;
  background: none !important;
  box-shadow: none !important;
}

.google-btn svg {
  transform: scale(1.8) !important;
}

.google-btn>div div:last-child {
  display: none !important;
  border: none !important;
}

.google-btn:hover {
  background: none !important;
}

.forgot-password:hover {
  color: var(--clr-dark) !important;
}

.blue-hover:hover {
  color: var(--clr-blue) !important;
}

.with-facebook,
.with-google {
  /* box-shadow: var(--shadow-2); */
  /* padding: 5px 10px; */
  border: 1px solid var(--clr-light-dark-2);
  border-radius: var(--radius-2);
  height: 3.6rem;
  width: 25rem;
  /* margin: 3px 0; */
  transition: var(--transition);
}

.with-social-sepetaror>p {
  margin: 10px 0 !important;
  letter-spacing: 1px;
}

.with-facebook:hover,
.with-google:hover {
  color: var(--clr-pink) !important;
}

.with-social-sepetaror::before {
  content: '' !important;
  width: 10rem !important;
  height: 1.5px !important;
  background-color: var(--clr-light-dark-2) !important;
  position: absolute !important;
  display: none;
  left: 40%;
  margin: 21px 0 0 0;
  border-radius: var(--radius-2);
}

.with-social-sepetaror::after {
  content: '' !important;
  width: 10rem !important;
  height: 1.5px !important;
  background-color: var(--clr-light-dark-2) !important;
  position: absolute !important;
  display: none;
  right: 40%;
  margin: -22px 0 0 0;
  border-radius: var(--radius-2);
}