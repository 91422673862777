.top-text-success,
.top-text-danger {
    padding: 10px 0px;
    border-radius: var(--radius);
    margin: 0 0 3rem 0;
    font-size: 17px;
}

.top-text-success {
    background-color: var(--clr-light-success);
    color: var(--clr-success);
}

.top-text-danger {
    background-color: var(--clr-light-danger);
    color: var(--clr-danger);
}