.review-rating .star-svg {
    width: 25px !important;
}

.reviews-by-rating .star-svg {
    width: 21px !important;
}

.total-reviews .star-svg {
    width: 25px !important;
}

.text-blue {
    color: var(--clr-blue);
}

/* 
.total-reviews-item::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: var(--clr-blue);
    left: 0;
    bottom: 0px;
    border-radius: 10px;
    overflow: hidden;
} */

/* .reviews-title::after{
        content: "";
        position: absolute;
        width: 0;
        height: 3px;
        bottom: 0;
        left: 0;
        background: #258f67;

} */

.reviews-title {
    position: relative;
}

.reviews-title::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--clr-blue);
    left: 0;
    bottom: 0px;
    border-radius: 10px;
}


.reviews-title::before {
    content: '';
    position: absolute;
    width: 40%;
    height: 3px;
    background-color: var(--clr-blue);
    bottom: 0px;
    border-radius: 50px;
}