/* search-box */

.search-box {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 98%;
    background: var(--clr-light);
    border-radius: 10px;
    box-shadow: var(--shadow-4);
    padding: 1rem;
    font-family: var(--font-family-outfit-reg);
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: opacity 0.7s ease, height 0.7s ease, margin 0.7s ease, all 0.7s ease;
    margin: -2rem 0 2rem 0rem !important;
}

.search-box.show {
    opacity: 1;
    height: auto;
    max-height: 40rem;
    overflow: scroll;
    padding: 1rem;
    z-index: 9999 !important;
}

.nav-box.show {
    margin: 2rem 0 7rem 0rem !important;
    top: 16%;
}

.top-box.show {
    margin: 7rem 0 7rem 0rem !important;
    top: 100%;

}


.input-group-append {
    margin-top: -2.3rem;
    position: absolute;
    top: 100%;
}

.search-box-title {
    color: var(--gray-2);
    opacity: 0.8;
}

.search-box-icon {
    font-size: 29px;
    color: var(--clr-pink);
    cursor: pointer;
    transition: var(--transition);
    margin: 0 15px 0 0;
}

.search-box-icon:hover {
    color: var(--clr-dark);
}

.search-box-input {
    width: 100%;
    border: 1px solid #BE5CA93D;
    border-radius: var(--radius) !important;
    margin-top: 1.7rem;
    outline: none !important;
    font-size: 20px;
    transition: var(--transition);
    height: 2.9rem;
    padding: 0px 10px;
}

.search-box-input:focus {
    border: 1px solid #be5ca97c !important;
    box-shadow: none !important;
    box-shadow: var(--shadow-pink) !important;
}

.search-box-input::placeholder {
    font-size: 19px;
    color: var(--gray);
}


@media screen and (min-width: 1200px) and (max-width: 1510px) {
    .search-box-input {
        width: 90%;
        margin-left: 1rem;
    }
}