/*  */
.modal {
    transition: all .75s linear !important;

}

.modal-dialog {
    max-width: 600px !important;
    transition: var(--transition) !important;
}

.modal-content {
    transform: scale(0.4);
    transition: transform 0.7s ease;
    min-width: 54rem;
}

.col-desc {
    max-height: 525px;
    overflow: auto;
}

.modal.fade.show .modal-content {
    transform: scale(1);
}



@media screen and (max-width:992px) {
    .col-desc {
        max-height: 525px;
        overflow: auto;
    }
}


@media screen and (max-width:768px) {
    .col-desc {
        max-height: 400px;
        overflow: auto;
    }
}

@media screen and (max-width:578px) {
    .col-desc {
        max-height: 370px;
        overflow: auto;
    }
}

/*  */

@media screen and (max-width:1200px) {
    .modal-content {
        min-width: 50rem;
    }
}

@media screen and (max-width:768px) {
    .modal-content {
        min-width: 44rem;
    }

    .product-slider .p-sliderImg img {
        width: 80% !important;
    }
}

@media screen and (max-width:630px) {
    .modal-content {
        min-width: 40rem;
    }
}

@media screen and (max-width:590px) {
    .modal-content {
        min-width: 35rem;
    }

    .product-slider .p-sliderImg img {
        width: 70% !important;
    }
}

/* ========================== */

@media screen and (max-width:850px) {
    .modal-dialog {
        transform: scale(0.94) !important;
    }
}

@media screen and (max-width:768px) {
    .modal-dialog {
        transform: scale(0.85) !important;
    }

    .modal.fade.show .modal-content {
        transform: scale(.85);
    }

    .modal-product-desc {
        font-size: 22px !important;
    }
}

@media screen and (max-width:550px) {
    .modal-dialog {
        transform: scale(0.80) !important;
    }
}

.modal-content {
    box-shadow: var(--shadow-2) !important;
    border-radius: 10px !important;
    border: 1px solid var(--clr-light-dark-2) !important;
    transition: var(--transition) !important;

    max-height: 57rem !important;
    min-height: auto !important;
    /* overflow: scroll !important; */
}



.modal-header {
    display: block !important;
    height: 0px !important;
}

.modal-close-btn {
    outline: none !important;
    margin: -34px -10px 0 0px !important; 
    font-size: 37px !important;
    /* margin: -40px -10px 0 0px !important;
    font-size: 55px !important; */
    border: none !important;
}

@media screen and (max-width:768px) {
    .modal-header {
        height: 3rem !important;
    }

    .modal-close-btn {
        margin: -40px -10px 0 0px !important;
        font-size: 55px !important;
    }
}

.modal-product-title {
    font-family: var(--font-family-outfit-medium) !important;
    margin: 15px 0 10px 0 !important;
    transition: var(--transition);
}

.modal-product-title:hover {
    color: var(--clr-blue);
}

.modal-product-prix {
    color: var(--clr-blue) !important;
    font-size: 20px;
}

.modal-product-desc {
    font-family: var(--font-family-outfit-medium) !important;
    color: var(--gray-2) !important;
    font-size: 18px;
}

.addtocard-btn {
    background-color: var(--clr-blue) !important;
    padding: 8px 20px !important;
    color: var(--clr-light) !important;
    font-size: 9px !important;
    text-transform: uppercase;
    letter-spacing: .9px;
    transition: var(--transition) !important;
}

.addtocard-btn:hover {
    background-color: var(--clr-dark) !important;

}

.modal-card-icon {
    font-size: 20px !important;
    margin: 0px 5px 0 -7px;
}

.modal-image.img-fluid,
.img-thumbnail {
    height: auto !important;
    max-width: 67% !important;
    max-height: 25rem;
    min-height: 20rem;
}





/* Modal */

.col-descc::-webkit-scrollbar-track,
.modal::-webkit-scrollbar-track {
    display: none !important;
    border-radius: 10px !important;
}

.col-descc::-webkit-scrollbar,
.modal::-webkit-scrollbar {
    display: none !important;
    width: 8px !important;
    height: 30% !important;
}

.col-descc::-webkit-scrollbar-thumb,
.modal::-webkit-scrollbar-thumb {
    display: none !important;
    border-radius: 10px !important;
    background-color: var(--clr-light-dark) !important;
}



/* .fade{
    transform: rotateX(-90deg) !important;
  } */
/* .fade-flsip {
    transform: rotateX(-90deg);
    opacity: 0;
    transition: all .25s linear;
  }
  .fade-flip.in {
    opacity: 1;
    transform: rotateX(0deg);
    display: block;
  }
  .fade-flip .modal-dialog {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%) !important;
  } */

/* .modal-dialog {
      position: absolute !important;
      left: 0 !important;
      right: 0 !important;
      top: 50% !important;
      transform: translateY(-50%) !important ;
  } */